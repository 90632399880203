import React, { useState, useEffect, useRef } from 'react'
import { Modal, Row, Col, Button, Collapse, Toast, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'
import api from '../utils/API'
import util from '../utils/utils'
import { isDataActive } from '../utils/common'
import Spinner from '../components/spinner'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown, faInfoCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser'

// custom component
const CloseButton = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  border: 3px solid #006778 !important;
  background: white;
  text-align: center;
  font-weight: 700;
  color: #006778;
  display: block;
  z-index: 100;
  * {
    pointer-events: none;
  }
`

const InputIconWrapper = styled.div`
position: relative;
svg {
  position: absolute;
  top: 12px;
  right: 20px;
}
`

const DetailModal = props => {

  const { isOpen, consentDetail, FnCloseDetail } = props

  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [consent, setConsent] = useState({})
  const [openAccordion, setOpenAccordion] = useState({
    NAME_AND_OCCUPATION_AND_CONTACT_DETAILS: false,
    NAME_AND_OCCUPATION: false,
    ACCOUNT_BALANCE_AND_DETAILS: false,
    ACCOUNT_NAME_TYPE_AND_BALANCE: false,
    TRANSACTION_DETAILS: false,
    SAVED_PAYEES: false,
    DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS: false
  })
  const [tag, setTag] = useState('')
  const [showToaster, setShowToaster] = useState(false)
  const [toasterMessage, setToasterMessage] = useState({
    success: false,
    message: ''
  })

  const updateTag = (consentId, tag) => {
    api.setConsentTag(consentId, { tag })
      .then(() => {
        setShowToaster(true)
        setToasterMessage({
          success: true,
          message: 'Description has been update successfully'
        })
      })
      .catch((err) => {
        console.error("error", err)
        setShowToaster(true)
        setToasterMessage({
          success: false,
          message: 'Unable to update description into the system'
        })
      })
  }

  // using useRef, the value does not get re-evaluated every time the functional component is executed
  const debounceSave = useRef(_.debounce(updateTag, 1000)).current


  const closeToaster = () => {
    setShowToaster(false)
  }

  const setAccordion = (data) => {
    setOpenAccordion(p => {
      p[data] = !openAccordion[data]
      return { ...p }
    })
  }


  const revokeConsent = () => {
    setIsLoading(true)
    api.revokeConsent(consent.consentId)
      .then((res) => {
        setIsLoading(false)
        setIsRevokeOpen(false)
        openConfirmModal()
        setConsent(res.data)
      })
      .catch((err) => {
        setIsLoading(false)
        setError("Unable to revoke consent, please contact our friendly support team member.")
        console.error("revoke error", err)
      })
  }

  const handleTagChange = (e) => {
    e.preventDefault()
    setTag(e.target.value)
    debounceSave(consent.consentId, e.target.value)
  }

  const closeDetailModal = () => {
    setOpenAccordion({
      NAME_AND_OCCUPATION_AND_CONTACT_DETAILS: false,
      NAME_AND_OCCUPATION: false,
      ACCOUNT_BALANCE_AND_DETAILS: false,
      ACCOUNT_NAME_TYPE_AND_BALANCE: false,
      TRANSACTION_DETAILS: false,
      SAVED_PAYEES: false,
      DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS: false
    })
    setToasterMessage({
      success: false,
      message: ''
    })
    setShowToaster(false)
    FnCloseDetail()
  }

  useEffect(() => {
    setShow(isOpen)
    if (isOpen) {
      setConsent(consentDetail)
      console.log("consents", consentDetail)
      api.getConsentTag(consentDetail.consentId)
        .then((res) => {
          if (res.data && res.data.tag) {
            setTag(res.data.tag)
          } else {
            setTag('')
          }
        })
        .catch((err) => {
          console.error("err getting tag", err)
        })
    }
  }, [isOpen])

  let untilTime = ''
  let statusText = ''
  if (Object.keys(consent).length > 0) {
    if (util.deep_value(consent, 'dataUsage.status') === 'REVOKED') {
      untilTime = moment(util.deep_value(consent, 'dataUsage.revokedDate'))
    } else {
      untilTime = util.deep_value(consent, 'auth.timeline.consented') !== null && moment(util.deep_value(consent, 'auth.timeline.consented'))
      if (util.deep_value(consent, 'dataUsage.periodOfUse.days')) {
        untilTime.add(util.deep_value(consent, 'dataUsage.periodOfUse.days'), 'days')
      }
      if (util.deep_value(consent, 'dataUsage.periodOfUse.months')) {
        untilTime.add(util.deep_value(consent, 'dataUsage.periodOfUse.months'), 'months')
      }
    }


    if (util.deep_value(consent, 'auth.status.current') === false) {
      statusText = statusText + 'We are no longer collecting this data.'
      if (util.deep_value(consent, 'dataUsage.status') === 'REVOKED') {
        statusText = statusText + ` Consent has been revoked on ${untilTime.format('DD MMM YYYY')}`
      } else if (util.deep_value(consent, 'dataUsage.status') !== 'EXPIRED') {
        statusText = statusText + ` We may use the collected data until ${untilTime.format('DD MMM YYYY')}`
      } else {
        statusText = statusText + ' We are no longer using this data.'
      }
    } else {
      statusText = 'We are actively collecting this data.'
    }
  }

  let consentUseUntilTime = util.deep_value(consent, 'auth.timeline.consented') !== null ? moment(util.deep_value(consent, 'auth.timeline.consented')) : null
  if (consentUseUntilTime) {
    if (util.deep_value(consent, 'dataUsage.periodOfUse.days')) {
      consentUseUntilTime.add(util.deep_value(consent, 'dataUsage.periodOfUse.days'), 'days')
    }
    if (util.deep_value(consent, 'dataUsage.periodOfUse.months')) {
      consentUseUntilTime.add(util.deep_value(consent, 'dataUsage.periodOfUse.months'), 'months')
    }
  }

  /// revoke modal functions ////
  const [isRevokeOpen, setIsRevokeOpen] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [error, setError] = useState(null)

  const openRevokeModal = () => {
    setIsRevokeOpen(true)
  }

  const closeRevokeModal = () => {
    setIsRevokeOpen(false)
  }

  const openConfirmModal = () => {
    setIsConfirmOpen(true)
  }

  const ConfirmationModal = ({ isOpen }) => {
    return (
      <Modal show={isOpen} onHide={() => setIsConfirmOpen(false)}>
        <Modal.Header className="p-3 justify-content-center" style={{ backgroundColor: '#E2F0F0' }}>
          <h4 className="text-dark text-bold"><b>Revoke Confirmation</b></h4>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-column">
            <h5>Consent has been revoked successfully on {moment().format('DD MMM YYYY')}</h5>
            <FontAwesomeIcon icon={faCheckCircle} color="#373737" size="6x" />
          </div>
          <Row className="py-3">
            <Button variant="dark" className="mx-4 btn-block" onClick={() => setIsConfirmOpen(false)}>OK</Button>
          </Row>
        </Modal.Body>
      </Modal >
    )
  }


  const ConfirmRevokeModal = ({ isOpen }) => {
    return (
      <Modal show={isOpen} onHide={closeRevokeModal}>
        <Modal.Header className="p-0" style={{ backgroundColor: '#E2F0F0' }}>
        </Modal.Header>
        <Modal.Body>
          <p><b className="text-dark">Current data usage</b></p>
          <p>We currently have your consent to use your data for purpose of assessing your loan application until {untilTime !== '' && untilTime.format('DD MMM YYYY')}</p>
          <p><b className="text-dark">What will happen?</b></p>
          <p>If you ask us to stop using your data, we will. All data will be deleted except for data that we are required to ratain by law.<br></br>
          It is important for you to understand that <b>without this data, we may not be able to progress you loan application</b><br></br>
          </p>
          <p><b className="text-dark">Are you sure you would like us to stop using your data?</b></p>
          <Row className="py-3">
            <Button variant="dark" className="mx-4 btn-block" onClick={revokeConsent}>Yes. Stop using my data</Button>
          </Row>
          <Row className="py-3">
            <Button variant="light" className="noButton mx-4 btn-block" onClick={closeRevokeModal}>No. Go back.</Button>
          </Row>
          {
            error &&
            <div className="alert alert-danger py-2" >{error}</div>
          }
        </Modal.Body>
      </Modal>
    )
  }

  /// revoke modal  ////

  return (
    <div key='detailModal'>
      {
        Object.keys(consent).length > 0 &&
        <Modal show={show} onHide={closeDetailModal}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 20
            }}>
            <Toast style={{ backgroundColor: toasterMessage.success ? '#28a745' : '#dc3545' }} show={showToaster} onClose={closeToaster} delay={2000} autohide>
              <Toast.Body style={{ color: 'white' }}>{toasterMessage.message}</Toast.Body>
            </Toast>
          </div>
          <Modal.Header className="p-0" style={{ backgroundColor: '#E2F0F0' }}>
            <div className="container p-4">
              <Row>
                <Col xs={3} className="d-flex align-items-start justify-content-center pt-1">
                  <img
                    alt=""
                    src={util.deep_value(consent, 'dataholder.logoUri')}
                    width="60"
                    className="d-inline-block align-center mb-0 object-fit_contain"
                  />
                </Col>
                <Col xs={9}>
                  <h3 className="text-dark">{util.deep_value(consent, 'dataholder.brandName')}</h3>
                  <p className="mb-0">
                    <b className="bold-text text-dark">For: </b>{util.deep_value(consent, 'softwareProduct.CxTexts.PurposeCX')}<br />
                    <b className="bold-text text-dark">Data Collection Date: </b>{util.deep_value(consent, 'auth.timeline.consented') !== null && moment(util.deep_value(consent, 'auth.timeline.consented')).format('DD MMM YYYY')}<br />
                    <b className="bold-text text-dark">Data Use Expires: </b>{untilTime.format('DD MMM YYYY')}<br />
                    <b className="bold-text text-dark">Status: </b><span className="text-105"><Badge variant={!isDataActive(consent) ? 'danger' : 'success'}>{!isDataActive(consent) ? 'Inactive' : 'Active'}</Badge></span><br />
                  </p>
                  <Row>
                    <div className="d-flex flex-grow-0 pl-3 align-items-center">
                      <b className="bold-text text-dark">Your tag:</b>
                    </div>
                    <Col className="d-flex px-0 flex-grow-1">
                      <div className="container">
                        <input type="text" className="form-control px-1 tag-input" aria-describedby="description" placeholder="Your description of this sharing" value={tag} onChange={handleTagChange} />
                      </div>
                    </Col>
                  </Row>

                </Col>
              </Row>
              <Row>
                <p>{statusText}</p>
              </Row>

              {
                moment(new Date()).isBefore(untilTime) &&
                <Row>
                  <Button variant="dark" className="mx-4 btn-block" disabled={util.deep_value(consent, 'dataUsage.status') === 'REVOKED'} onClick={openRevokeModal}>{util.deep_value(consent, 'dataUsage.status') === 'REVOKED' ? 'Revoked' : 'Stop using my data'}</Button>
                </Row>
              }

            </div>
            <CloseButton className="cursor-pointer" onClick={closeDetailModal}>X</CloseButton>
          </Modal.Header>
          <Modal.Body>
            <h4><b className="text-dark">About your data</b></h4>
            <p><b className="text-dark">What was collected</b></p>
            {Object.keys(util.deep_value(consent, 'dataCollection.Clusters')).map((cluster, inx) => {
              let consentConfirmed = util.deep_value(consent, 'auth.scopes.confirmed')
              let base = `softwareProduct.CxTexts.AboutDataCollection.Clusters.${cluster}`
              let dataCollectionBase = `dataCollection.Clusters.${cluster}`
              // For Account name, type and balance, if the same request contain 'Account Balance and details', we do not need to display it
              if (util.deep_value(consent, `${base}.Scope`) === 'bank:accounts.basic:read') {
                if (util.deep_value(consent, 'softwareProduct.CxTexts.AboutDataCollection.Clusters.ACCOUNT_BALANCE_AND_DETAILS')) {
                  return
                }
              }
              // For 'Name and occupation', if the same request contain 'Name, occupation, contact details', we do not need to display it
              if (util.deep_value(consent, `${base}.Scope`) === 'common:customer.basic:read') {
                if (util.deep_value(consent, 'softwareProduct.CxTexts.AboutDataCollection.Clusters.NAME_AND_OCCUPATION_AND_CONTACT_DETAILS')) {
                  return
                }
              }
              if (consentConfirmed.includes(util.deep_value(consent, `${base}.Scope`))) {
                return (
                  <>
                    <div className="pb-2" key={inx}>
                      <InputIconWrapper>
                        <FontAwesomeIcon icon={openAccordion[cluster] === false ? faChevronRight : faChevronDown} color="white" />
                      </InputIconWrapper>
                      <Button
                        onClick={() => setAccordion(cluster)}
                        aria-controls="OpenTransaction"
                        className="btn-block btn-dark btn-outline-dark text-white"
                      >
                        {util.deep_value(consent, `${base}.Consent`)}
                      </Button>
                      <Collapse in={openAccordion[cluster]}>
                        <div className="border border-top-0 border-dark px-2 py-2" id="collapse-transactions">
                          <p><b className="text-dark">Why we need it</b></p>
                          <p>{util.deep_value(consent, `${base}.Why`)}</p>
                          <p><b className="text-dark">What we've collected</b></p>
                          <ul>{util.deep_value(consent, `${base}.Permissions`).map((x, i) => <li key={i}>{ReactHtmlParser(x)}</li>)}</ul>
                          {util.deep_value(consent, `${dataCollectionBase}.historicalDataCollection.earliestHistorical`) && (
                            <>
                              <p><b className="text-dark">Historical data we've collected</b></p>
                              <p>{util.deep_value(consent, `${dataCollectionBase}.historicalDataCollection.earliestHistorical`) ? 'We have collected transaction data that dates back to ' + moment(util.deep_value(consent, `${dataCollectionBase}.historicalDataCollection.earliestHistorical`)).format('DD MMM YYYY') : "No historical data has been collected"}</p>
                            </>
                          )}
                          <p><b className="text-dark">When we've collected your data</b></p>
                          <p>We collected your data once only on {util.deep_value(consent, `${dataCollectionBase}.whenCollected.onceOnly`) && moment(util.deep_value(consent, `${dataCollectionBase}.whenCollected.onceOnly`)).format('DD MMM YYYY')}</p>
                        </div>
                      </Collapse>
                    </div>
                  </>
                )
              } else {
                return
              }
            })}
            <div>
              <p><b className="text-dark">What happened to your data that we collected</b></p>
              <p>{util.deep_value(consent, 'softwareProduct.CxTexts.WhatHappened')}</p>
              <p><b className="text-dark">How your data was used</b></p>
              <p>{util.deep_value(consent, 'softwareProduct.CxTexts.HowUsed')}</p>
              <p><b className="text-dark">Sharing with third parties</b></p>
              <p>{util.deep_value(consent, 'softwareProduct.CxTexts.SharingWithThirdParties')} <br />
                <a href="https://www.cuscalpayments.com.au/download/cuscal-cdr-policy/" target="_blank">See more information about our third party providers</a> </p>
              <p><b className="text-dark">How often we collected your data</b></p>
              <p>{util.deep_value(consent, 'auth.consentType') == 'ONE_TIME' ? 'We collected your data once only, as a point-in-time snapshot on ' + moment(util.deep_value(consent, 'auth.timeline.consented')).format('DD MMM YYYY') + '.' : 'We collected your data multiple times between ' + moment(util.deep_value(consent, 'auth.timeline.consented')).format('DD MMM YYYY') + ' and ' + moment(util.deep_value(consent, 'auth.timeline.sharingEnd')).format('DD MMM YYYY')}</p>
              <p><b className="text-dark">Key dates</b></p>
              <p>When you gave consent: {util.deep_value(consent, 'auth.timeline.consented') !== null ? moment(util.deep_value(consent, 'auth.timeline.consented')).format('DD MMM YYYY') : 'n/a'}</p>
              <p>You consented for us to collect this data until: {util.deep_value(consent, 'auth.timeline.sharingEnd') !== null ? moment(util.deep_value(consent, 'auth.timeline.sharingEnd')).format('DD MMM YYYY') : moment(util.deep_value(consent, 'auth.timeline.consented')).format('DD MMM YYYY')}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      We were authorised to collect your data from the data holder until this date.
                </Tooltip>}><span className="px-1"><FontAwesomeIcon icon={faInfoCircle} style={{ color: '#373737' }} /></span></OverlayTrigger></p>
              <p>You consented for us to use this data until: {consentUseUntilTime !== null ? moment(consentUseUntilTime).format('DD MMM YYYY') : 'n/a'}
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      We were authorised to use the collected data until this date.
                </Tooltip>
                  }><span className="px-1"><FontAwesomeIcon icon={faInfoCircle} style={{ color: '#373737' }} /></span></OverlayTrigger></p>
              {util.deep_value(consent, 'dataUsage.status') === 'REVOKED' ? <p>You withdrew your consent: {util.deep_value(consent, 'dataUsage.revokedDate') !== null ? moment(util.deep_value(consent, 'dataUsage.revokedDate')).format('DD MMM YYYY') : 'n/a'}</p>
                : util.deep_value(consent, 'dataUsage.status') === 'EXPIRED' && <p>Your consent expired: {consentUseUntilTime !== null ? moment(consentUseUntilTime).format('DD MMM YYYY') : 'n/a'}</p>}
            </div>
          </Modal.Body>
        </Modal>
      }
      <ConfirmRevokeModal isOpen={isRevokeOpen} />
      <ConfirmationModal isOpen={isConfirmOpen} />
      <Spinner isLoading={isLoading} />
    </div >
  )
}

export default DetailModal