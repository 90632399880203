
import moment from 'moment'
import util from './utils'



export const isDataActive = (data, isActive) => {
  let untilTime = ''
  if (util.deep_value(data, 'dataUsage.status') === 'REVOKED') {
    untilTime = moment(util.deep_value(data, 'dataUsage.revokedDate'))
  } else {
    untilTime = util.deep_value(data, 'auth.timeline.consented') !== null && moment(util.deep_value(data, 'auth.timeline.consented'))
    if (util.deep_value(data, 'dataUsage.periodOfUse.days')) {
      untilTime.add(util.deep_value(data, 'dataUsage.periodOfUse.days'), 'days')
    }
    if (util.deep_value(data, 'dataUsage.periodOfUse.months')) {
      untilTime.add(util.deep_value(data, 'dataUsage.periodOfUse.months'), 'months')
    }
  }
  if (isActive !== null && isActive !==undefined){
    return untilTime.isSameOrAfter(new moment()) && (util.deep_value(data, 'dataUsage.status') !== 'EXPIRED' && util.deep_value(data, 'dataUsage.status') !== 'REVOKED' && data.auth.status.current === isActive)
    || (untilTime.isSameOrAfter(new moment()) && data.auth.status.current === !isActive && util.deep_value(data, 'dataUsage.status') !== 'EXPIRED' && util.deep_value(data, 'dataUsage.status') !== 'REVOKED')
  }
  return untilTime.isSameOrAfter(new moment()) && (util.deep_value(data, 'dataUsage.status') !== 'EXPIRED' && util.deep_value(data, 'dataUsage.status') !== 'REVOKED' && data.auth.status.current === true)
  || (untilTime.isSameOrAfter(new moment()) && data.auth.status.current === false && util.deep_value(data, 'dataUsage.status') !== 'EXPIRED' && util.deep_value(data, 'dataUsage.status') !== 'REVOKED')
}