import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Card, Dropdown, Col, Container, Row, Badge } from 'react-bootstrap'
import moment from 'moment'
import api from '../utils/API'
import util from '../utils/utils'
import { isDataActive } from '../utils/common'
import { navTo } from "../utils/navigation"
import Header from '../components/header'
import Spinner from '../components/spinner'
import DetailModal from '../components/detailModal'
import Layout from '../components/layout'

const Consents = () => {

  const [data, setData] = useState({})
  const [isActive, setIsActive] = useState(true)
  const [workingList, setWorkingList] = useState([])
  const [sortBy, setSortBy] = useState('Date')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isModalOpen, setOpenDetailModal] = useState(false)
  const [activeDetailConsent, setActiveDetailConsent] = useState({})
  const arrowToken = "->";

  const handleError = (err) => {
    setIsLoading(false)
    const { response } = err
    if (response && response.status === 403) {
      navTo.root()
    } else {
      if (response && response.data) {
        setError(response.data.error)
      } else {
        setError("Unexpected Error, please contact our friendly support person")
      }
    }
  }



  const toggleActive = (isActive) => {
    setIsActive(isActive)
  }

  const selectFilter = (name) => {
    setSortBy(name)
  }

  const openDetailConsent = (consent) => {
    setActiveDetailConsent(consent)
    setOpenDetailModal(true)
  }

  const closeDetailConsent = () => {
    setOpenDetailModal(false)
    // refresh data one close
    setIsLoading(true)
    api.getConsents()
      .then((result) => {
        setIsLoading(false)
        const { data: resultData, status } = result
        setData(resultData)
      })
      .catch((err) => {
        handleError(err)
      })
  }


  const filterActive = (active, data) => {
    return active ? isDataActive(data, active) : !isDataActive(data, active)
  }

  // initial load
  useEffect(() => {
    setIsLoading(true)
    api.getConsents()
      .then((result) => {
        setIsLoading(false)
        const { data: resultData, status } = result
        console.log("resultData", resultData)
        setData(resultData)
      })
      .catch((err) => {
        handleError(err)
      })
  }, [])

  useEffect(() => {
    const sortConsents = (a, b) => {
      if (typeof util.deep_value(a, 'auth.timeline.consented') === 'string' && typeof util.deep_value(b, 'auth.timeline.consented') === 'string') {
        if (moment(util.deep_value(a, 'auth.timeline.consented')).isBefore(moment(util.deep_value(b, 'auth.timeline.consented')))) {
          return -1
        }
        if (moment(util.deep_value(a, 'auth.timeline.consented')).isAfter(moment(util.deep_value(b, 'auth.timeline.consented')))) {
          return 1
        }
      }
      return 0
    }

    const sortByBankName = (a, b) => {
      let nameA = util.deep_value(a, 'dataholder.brandName').toUpperCase(); // ignore upper and lowercase
      let nameB = util.deep_value(b, 'dataholder.brandName').toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    }

    if (data && data.consents && Array.isArray(data.consents)) {
      if (sortBy === 'Bank') {
        setWorkingList(data.consents.filter((x) => filterActive(isActive, x)).sort(sortByBankName))
      } else {
        setWorkingList(data.consents.filter((x) => filterActive(isActive, x)).sort(sortConsents))
      }
    }
  }, [data, isActive, sortBy])

  return (
    <Layout>
      <div className="container py-4">
        <ButtonGroup className="d-flex mt-2">
          <Button variant="outline-dark" onClick={() => toggleActive(true)} active={isActive}>Active ({data.consents ? data.consents.filter((x) => filterActive(true, x)).length : ''})</Button>
          <Button variant="outline-dark" onClick={() => toggleActive(false)} active={isActive === false}>Inactive ({data.consents ? data.consents.filter((x) => filterActive(false, x)).length : ''})</Button>
        </ButtonGroup>
        <div className="d-flex justify-content-end align-items-center py-2">
          Sort by
          <Dropdown variant="light" size="sm" className="pl-2">
            <Dropdown.Toggle>
              {sortBy}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => selectFilter('Date')} active={sortBy === 'Date'}>Date</Dropdown.Item>
              <Dropdown.Item onClick={() => selectFilter('Bank')} active={sortBy === 'Bank'}>Bank</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {
          workingList.length === 0 && (
            <Card className="my-4" style={{ height: '150px' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <b>No data sharing activity in this group</b>
              </Card.Body>
            </Card>
          )
        }
        {
          workingList.map((consent, inx) => {
            let untilTime = ''
            if (util.deep_value(consent, 'dataUsage.status') === 'REVOKED') {
              untilTime = moment(util.deep_value(consent, 'dataUsage.revokedDate'))
            } else {
              untilTime = util.deep_value(consent, 'auth.timeline.consented') !== null && moment(util.deep_value(consent, 'auth.timeline.consented'))
              if (util.deep_value(consent, 'dataUsage.periodOfUse.days')) {
                untilTime.add(util.deep_value(consent, 'dataUsage.periodOfUse.days'), 'days')
              }
              if (util.deep_value(consent, 'dataUsage.periodOfUse.months')) {
                untilTime.add(util.deep_value(consent, 'dataUsage.periodOfUse.months'), 'months')
              }
            }
            let statusText = ''
            if (consent.auth && util.deep_value(consent, 'auth.status.current') === false) {
              statusText = statusText + 'We are no longer collecting this data.'
              if (util.deep_value(consent, 'dataUsage.status') === 'REVOKED') {
                statusText = statusText + ` Consent has been revoked on ${untilTime.format('DD MMM YYYY')}`
              } else if (util.deep_value(consent, 'dataUsage.status') !== 'EXPIRED') {
                statusText = statusText + ` We may use the collected data until ${untilTime.format('DD MMM YYYY')}`
              } else {
                statusText = statusText + ' We are no longer using this data.'
              }
            } else {
              statusText = 'We are actively collecting this data.'
            }
            return (
              <Card key={inx} className="my-4">
                <Card.Body>
                  <Row>
                    <Col xs={3} className="d-flex align-items-start justify-content-center pt-1">
                      <img
                        alt=""
                        src={util.deep_value(consent, 'dataholder.logoUri')}
                        width="70"
                        height="100%"
                        className="d-inline-block align-center mb-0 object-fit_contain"
                      />
                    </Col>
                    <Col xs={9}>
                      <Card.Title><h3 className="text-dark">{util.deep_value(consent, 'dataholder.brandName')}</h3></Card.Title>
                      <p>
                        <b className="bold-text text-dark">You gave consent on: </b>{moment(util.deep_value(consent, 'auth.timeline.consented')).format('DD MMM YYYY')}<br />
                        <b className="bold-text text-dark">For: </b>{util.deep_value(consent, 'softwareProduct.CxTexts.PurposeCX')}<br />
                        <b className="bold-text text-dark">Status: </b>{statusText}<br />
                        <Badge className="text-105" variant={!isDataActive(consent, isActive) ? 'danger' : 'success'}>{!isDataActive(consent, isActive) ? 'Inactive' : 'Active'}</Badge>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
                <Button variant="dark" className="mx-4 mb-4" onClick={() => openDetailConsent(consent)}>More details {arrowToken}</Button>
              </Card>
            )
          })
        }
      </div>
      <DetailModal isOpen={isModalOpen} FnCloseDetail={closeDetailConsent} consentDetail={activeDetailConsent} />
      <Spinner isLoading={isLoading} />
    </Layout>
  )
}

export default Consents
