const deep_value = (obj, path) => 
  path
  .replace(/\[|\]\.?/g, '.')
  .split('.')
  .filter(s => s)
  .reduce((acc, val) => acc && acc[val], obj)


module.exports = {
  deep_value
}